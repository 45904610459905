<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>

              <v-col cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <v-card flat>

                  <v-card-text v-if="caissesLoading">
                    <v-skeleton-loader v-for="el in 4" :key="el" type="list-item-avatar-two-line"/>
                  </v-card-text>

                  <v-card-text v-else class="pa-0">

                    <v-card outlined class="rounded mx-3 my-5">
                      <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
                        Caisses
                      </h5>
                      <v-list nav>
                        <v-list-item-group v-model="caisseId" :mandatory="!analytic" color="primary">
                          <v-list-item v-for="(caisse,i) in caisses"
                                       :key="i"
                                       :value="caisse.id"
                                       color="primary"
                                       @click="analytic = false">
                            <v-list-item-avatar color="primary" size="32">
                              <v-icon color="white">mdi-file-table-box-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ caisse.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>


                    <v-card outlined class="rounded mx-3 my-5">
                      <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
                        Menu
                      </h5>
                      <v-list nav>

                        <v-list-item to="/recoveries" exact>
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-folder-home-outline</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Principale</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-model="analytic"
                                     color="primary"
                                     value="analytic"
                                     @click="[analytic = true,caisseId=null]"
                        >
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-chart-pie</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Statistiques</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>


                  </v-card-text>
                </v-card>
              </v-col>

              <v-col v-if="!analytic" cols="12" lg="9">

                <v-card-title class="text--primary">
                  Paiements
                  <v-spacer/>

                  <v-btn depressed
                         exact
                         fab
                         small
                         to="/recoveries">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                </v-card-title>

                <v-divider/>

                <div v-if="!isDisbursement">
                  <div class="d-lg-flex d-block justify-space-between pa-3">

                    <v-btn class="rounded-lg text-none" color="grey" text>
                      <v-icon color="success" small>mdi-circle</v-icon>
                      Montant terminé
                    </v-btn>

                    <v-btn class="rounded-lg text-none" color="grey" text>
                      <v-icon color="warning" small>mdi-circle</v-icon>
                      Montant non terminé
                    </v-btn>

                    <v-spacer/>

                    <v-btn class="rounded-lg" color="gifty" text @click="isDisbursement = true">
                      <v-icon left>mdi-share-all-outline</v-icon>
                      Décaissements
                    </v-btn>

                  </div>

                  <v-divider/>

                  <v-card flat>

                    <v-card-title>
                      <div>
                        <v-text-field
                            v-model="keyword"
                            append-icon="mdi-magnify"
                            clearable
                            dense
                            filled
                            hide-details
                            placeholder="N°Facture, Mots clés..."
                            rounded
                            single-line
                        ></v-text-field>
                      </div>

                      <v-spacer/>

                      <v-btn color="grey" text @click="$refs.filterDialog.open()">
                        <v-icon left>mdi-filter-outline</v-icon>
                        Filtre
                      </v-btn>

                      <v-btn color="grey" text @click="handleExport">
                        <v-icon left>mdi-download-outline</v-icon>
                        Exporter
                      </v-btn>

                      <v-btn color="grey"
                             icon
                             @click="handleFilter">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>

                    </v-card-title>

                    <v-divider/>

                    <v-card-text v-if="isLoading || caissesLoading">
                      <v-skeleton-loader type="table"/>
                    </v-card-text>

                    <div v-else>

                      <v-card-text class="pa-0">

                        <v-simple-table v-if="payments.length">
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th></th>
                              <th>Recouvreur</th>
                              <th>Montant demandé</th>
                              <th>Montant donné</th>
                              <th>Montant restant</th>
                              <th>Date de recouvrement</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,i) in payments" :key="i">

                              <td>
                                <v-icon :color="(item.real_amount - item.paid_amount) === 0 ? 'success':'warning'"
                                        small>
                                  mdi-circle
                                </v-icon>
                              </td>

                              <td class="text-no-wrap">{{ item.recuperator.name }}</td>

                              <td>
                                  <span class="font-weight-medium text-no-wrap">
                                   {{ CurrencyFormatting(item.real_amount) }}
                                  </span>
                              </td>

                              <td>
                                 <span class="gifty--text font-weight-medium text-no-wrap">
                                   {{ CurrencyFormatting(item.paid_amount) }}
                                 </span>
                              </td>

                              <td>
                                 <span class="primary--text font-weight-medium text-no-wrap">
                                   {{ CurrencyFormatting(item.real_amount - item.paid_amount) }}
                                 </span>
                              </td>

                              <td class="text-no-wrap">
                                <v-icon :style="{marginTop:'-3px'}"
                                        color="gifty"
                                        small>mdi-clock-outline
                                </v-icon>
                                {{ item.date }}
                              </td>

                              <td>
                                <div class="d-flex">

                                  <v-btn :disabled="!item.remark"
                                         icon
                                         @click="$refs.remarkDialog.open(item.remark)">
                                    <v-icon>mdi-notebook-edit-outline</v-icon>
                                  </v-btn>

                                  <v-menu
                                      bottom
                                      right
                                      transition="slide-x-transition"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>

                                      <v-list-item @click="$refs.showDialog.open(item)">
                                        <v-list-item-icon>
                                          <v-icon>mdi-plus-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Détails de paiement</v-list-item-title>
                                      </v-list-item>

                                      <v-list-item @click="$refs.monetaryDetailsDialog.open(item.money)">
                                        <v-list-item-icon>
                                          <v-icon>mdi-plus-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Détails monétaire</v-list-item-title>
                                      </v-list-item>

                                      <v-list-item
                                          :disabled="!(item.real_amount - item.paid_amount)"
                                          @click="$refs.addRemainingAmountDialog.open(item)">
                                        <v-list-item-icon>
                                          <v-icon>mdi-cash-plus</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Ajouter le montant restant</v-list-item-title>
                                      </v-list-item>

                                      <v-list-item @click="printPDF(item)">
                                        <v-list-item-icon>
                                          <v-icon>mdi-clipboard-arrow-down-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Imprimer</v-list-item-title>
                                      </v-list-item>

                                    </v-list>
                                  </v-menu>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <div v-else>
                          <NoResults @reload="getPayments"/>
                        </div>

                      </v-card-text>

                      <v-divider v-if="total > 10"/>

                      <v-card-actions v-if="total > 10">

                        <div :style="{width: '120px'}">
                          <v-select v-model="perPage"
                                    :items="[10,20,30,50,100]"
                                    dense
                                    hide-details
                                    label="Ligne par page"
                                    outlined></v-select>
                        </div>

                        <v-spacer/>

                        <v-pagination v-model="pagination.current"
                                      :length="pagination.total"
                                      circle
                                      total-visible="8"
                                      @input="getPayments"></v-pagination>

                      </v-card-actions>

                    </div>

                  </v-card>
                </div>

                <div v-if="isDisbursement">
                  <Disburses :caisse="caisses.find(el=>el.id === caisseId)"
                             :disbursements.sync="disbursements"
                             :is-loading="isLoading"
                             @add="$refs.disburseDialog.open(caisseId,caisses.find(el=>el.id === caisseId).amount)"
                             @back="isDisbursement = false"
                             @refresh="getCaisses(caisseId)"/>
                </div>

              </v-col>

              <v-col v-else cols="12" lg="9">
                <v-card-title class="text--primary">
                  Statistiques
                  <v-spacer/>
                  <v-btn depressed
                         exact
                         fab
                         small
                         to="/recoveries">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider/>
                <div class="pa-2">
                  <StatisticCards ref="statisticCards"
                                  :loading="isLoading || caissesLoading"
                                  :statistics="statistics"
                                  class="mb-3"/>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </div>

      <FilterDialog ref="filterDialog" @filter="handleFilter"/>
      <AddRemainingAmountDialog ref="addRemainingAmountDialog" @refresh="getPayments"/>
      <ShowDialog ref="showDialog"/>
      <MonetaryDetailsDialog ref="monetaryDetailsDialog"/>
      <DisburseDialog ref="disburseDialog" @refresh="getCaisses(caisseId)"/>
      <RemarkDialog ref="remarkDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "./FilterDialog";
import AddRemainingAmountDialog from "./AddRemainingAmountDialog";
import MonetaryDetailsDialog from "./MonetaryDetailsDialog";
import ShowDialog from "./ShowDialog";
import StatisticCards from "../components/StatisticCards";
import Disburses from "@/views/recoveries/payments/Disburses";
import DisburseDialog from "@/views/recoveries/payments/DisburseDialog";
import RemarkDialog from "@/views/recoveries/payments/RemarkDialog";

export default {
  components: {
    Disburses,
    DisburseDialog,
    RemarkDialog,
    ShowDialog,
    NoResults,
    FilterDialog,
    StatisticCards,
    MonetaryDetailsDialog,
    AddRemainingAmountDialog
  },
  data() {
    return {
      isLoading: false,
      caissesLoading: false,
      overlay: false,
      payments: [],

      caisses: [],
      caisseId: null,
      keyword: null,
      status: null,
      dates: [],
      recuperatorIds: [],

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      statistics: {},
      analytic: false,

      disbursements: [],
      isDisbursement: false
    };
  },
  methods: {
    getPayments() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators/payments?page=' + this.pagination.current, {
        params: {
          recuperatorIds: this.recuperatorIds,
          status: this.status,
          dates: this.dates,
          perPage: this.perPage,
          caisseId: this.caisseId,
          keyword: this.keyword,
        }
      }).then(res => {
        this.isLoading = false
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.payments = res.data.data.data
        this.statistics = res.data.statistics
        this.disbursements = res.data.disbursements

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.status = e.status
      this.recuperatorIds = e.recuperatorIds
      this.getPayments()
    },
    handleExport() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.access_token
      let userId = this.$store.state.user.user.id
      let DownloadURL = baseUrl + '/api/v1/recoveries/payments/export-excel?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    printPDF(item) {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.access_token
      let userId = this.$store.state.user.user.id
      let DownloadURL = baseUrl + '/api/v1/recuperators/payment/export-pdf?token=' + token + '&id=' + userId + '&date=' + item.date + '&recuperator_id=' + item.recuperator_id;
      window.open(DownloadURL, '_blanc')
    },
    getCaisses(caisseId) {
      this.caissesLoading = true
      HTTP.get('/v1/caisses').then(res => {
        this.caissesLoading = false
        let caisses = res.data.data

        if (caisses.length) {
          this.caisses = caisses
          this.caisseId = caisseId ? caisseId : caisses[0].id
          this.getPayments()
        }

      }).catch(err => {
        this.caissesLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getCaisses()
  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getPayments()
    },
    keyword() {
      this.getPayments()
    },
    caisseId() {
      if (!this.analytic) {
        this.getPayments()
      }
    }
  },
};
</script>

<style scoped></style>
